import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Field, Fields, reduxForm, SubmissionError} from "redux-form";
import {FlexContainer} from "../components/FlexContainer";
import Button from "../components/Button";
import Label from "../components/Label";
import {PageHeading} from "../components/PageHeading";
import {MessageBox} from "../components/MessageBox";
import {ErrorText} from "../components/ErrorText";
import {ConfirmModal} from "../components/ConfirmModal";
import {HelpIcon} from "../components/HelpIcon";
import i18n from "../i18n";
import RadioButton from "../components/RadioButton";
import {StyledLink} from "../components/StyledLink";
import {Modal} from "../components/Modal";
import {getLanguage, isSuperUser} from "../util/common";
import SearchableSelectNew from "../components/SearchableSelectNew";
import {connect} from "react-redux";
import CheckBox from "../components/CheckBox";
import {Icon} from "../insiderTool/components";

export const DistrubutionInfo = styled.div``;

export const DistrubutionBody = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

const Text = styled.p`
  ${(props) => (props.bold ? "font-weight: 600;" : "")};
  font-size: 16px;
`;

export const SelectDropDown = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:
        {error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>

      <SearchableSelectNew
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        component={SearchableSelectNew}
        options={props.options}
        t={props.t}
      />
    </FlexContainer>
  );
};

const validate = (values, props) => {
  const errors = {};

  const minHeaderLength =
    props && props.messageParams && props.messageParams.messageTitleMinLength
      ? props.messageParams.messageTitleMinLength
      : 4;

  if (values.issuer == null) {
    errors.issuer = props.t("required_field");
  }

  //OBNT:2007 -> do not validate category if templates are in use. Rest of the logic sent to server is found in edit container, categoryID has it own state if
  //templates are in use...
  if (values.category == null) {
    if (props.templateList != null && props.templateList.length === 0) {
      errors.category = props.t("required_field");
    } else if (props.newsMessageForm.category != null) {
      errors.template = props.t("required_field");
    }
  }

  const nbMessageNotEntered =
    (values.message_no == null || values.message_no.length === 0) &&
    (values.title_no == null || values.title_no.length === 0);

  const enMessageNotEntered =
    (values.message_en == null || values.message_en.length === 0) &&
    (values.title_en == null || values.title_en.length === 0);

  const nbMessageValid = !(
    ((values.title_no == null || values.title_no.length === 0) &&
      values.message_no != null &&
      values.message_no.length > 0) ||
    ((values.message_no == null || values.message_no.length === 0) &&
      values.title_no != null &&
      values.title_no.length > 0)
  );

  const enMessageValid = !(
    ((values.title_en == null || values.title_en.length === 0) &&
      values.message_en != null &&
      values.message_en.length > 0) ||
    ((values.message_en == null || values.message_en.length === 0) &&
      values.title_en != null &&
      values.title_en.length > 0)
  );

  if (!nbMessageValid) {
    errors.title_no = props.t("message_need_to_be_valid");
  }

  if (!enMessageValid) {
    errors.title_en = props.t("message_need_to_be_valid");
  }

  if (
    values.title_no != null &&
    (values.title_no.length < minHeaderLength || values.title_no.length > 256)
  )
    errors.title_no = props.t("num_characters_message", { min: 4, max: 256 });
  if (
    values.title_en != null &&
    (values.title_en.length < minHeaderLength || values.title_en.length > 256)
  )
    errors.title_en = props.t("num_characters_message", { min: 4, max: 256 });

  if (
    values.message_no != null &&
    (values.message_no.length < 4 || values.message_no.length > 50000)
  )
    errors.message_no = props.t("num_characters_message", {
      min: 4,
      max: 50000,
    });
  if (
    values.message_en != null &&
    (values.message_en.length < 4 || values.message_en.length > 50000)
  )
    errors.message_en = props.t("num_characters_message", {
      min: 4,
      max: 50000,
    });

  const hasNBAttachment =
    values.attachments_no != null && values.attachments_no.length > 0;
  const hasENAttachment =
    values.attachments_en != null && values.attachments_en.length > 0;

  if (hasNBAttachment && nbMessageNotEntered) {
    errors.title_no = props.t("message_need_to_be_valid");
  }
  if (hasENAttachment && enMessageNotEntered) {
    errors.title_en = props.t("message_need_to_be_valid");
  }

  if (nbMessageNotEntered && enMessageNotEntered) {
    if (props.showEnInputFields && props.showNbInputFields)
      errors.title_no = props.t("one_message_need_to_be_valid");
    else if (props.showEnInputFields)
      errors.title_en = props.t("message_need_to_be_valid");
    else if (props.showNbInputFields)
      errors.title_no = props.t("message_need_to_be_valid");
  }
  return Object.keys(errors).length > 0 ? errors : undefined;
};

const hasEnteredEnAndNoMessage = (values) => {
  return (
    values.message_en != null &&
    values.message_en.length > 0 &&
    values.message_no != null &&
    values.message_no.length > 0
  );
};

const RadioButtonGroup = (props) => {
  //const input = {onChange: props.onCurrentTime}
  var isDisabled =
    props.obdnConfig != null ? props.obdnConfig.obdnLocked : false;

  return (
    <FlexContainer hidden={isDisabled} column padding={[0, 0, 0, 0]}>
      <RadioButton
        inactive={isDisabled}
        disabled={isDisabled}
        dontChangeOnMount
        checked={
          props.obdnConfig != null && props.obdnConfig.obdnOn === true
            ? props.obdnConfig.obdnOn
            : false
        }
        margin={[0, 0, 8, 0]}
        radioValue={true}
        label={props.t(
          props.obdnConfig != null ? props.obdnConfig.scenario + "_on" : ""
        )}
        input={{
          value: props.input.value,
          onChange: (value) => {
            props.setObdnOn();
            props.input.onChange(value);
          },
        }}
      />

      <RadioButton
        inactive={isDisabled}
        disabled={isDisabled}
        checked={
          props.obdnConfig != null && props.obdnConfig.obdnOn !== true
            ? props.obdnConfig.obdnOn
            : false
        }
        margin={[0, 0, 0, 0]}
        radioValue={false}
        label={props.t(
          props.obdnConfig != null ? props.obdnConfig.scenario + "_off" : ""
        )}
        input={{
          value: props.input.value,
          onChange: (value) => {
            props.showScenarioModal();
            props.setObdnOff();
            props.input.onChange(value);
          },
        }}
      />
    </FlexContainer>
  );
};

const distinctAttachmentLanguages = (values) =>
  ((values.attachments_no != null && values.attachments_no.length > 0) & 1) +
  ((values.attachments_en != null && values.attachments_en.length > 0) & 1);

const DistributionListBoxes = (props) => {
  return (
    <FlexContainer>
      {props.distributionLists && props.distributionLists.length > 0 ? (
        props.distributionLists.map((list) => (
          <Field
            name={"distribution_list_" + list.id}
            component={CheckBox}
            label={list.name}
            margin={[14, 0, 0, 0]}
            key={"distribution_list_key_" + list.id}
          />
        ))
      ) : (
        <Label>{props.t("email_distribution_no_lists")}</Label>
      )}
    </FlexContainer>
  );
};

class FirstForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAttachmentWarning: false,
      disregardAttachmentWarning: false,
      showCostWarning: false,
      costAccepted: false,
      showScenarioModal: false,
      test: this.props.newsMessageForm?.test === true,
      showTestWarning: false,
      disregardTestWarning: false,
      isObdnActive:
        this.props.obdnConfig != null
          ? this.props.obdnConfig.obdnOn
          : undefined,
    };
  }

  render() {
    const setObdnOff = () => {
      this.setState({ isObdnActive: false });
      return this.state.isObdnActive;
    };

    const setObdnOn = () => {
      this.setState({ isObdnActive: true });
      return this.state.isObdnActive;
    };

    const { t, showHelp } = this.props;

    const onSubmit = (values) => {
      const errors = validate(values, this.props);
      if (errors) {
        window.scroll(0, 0);
        return Promise.reject(new SubmissionError(errors));
      } else if (
        hasEnteredEnAndNoMessage(values) &&
        distinctAttachmentLanguages(values) < 2 &&
        distinctAttachmentLanguages(values) > 0 &&
        !this.state.disregardAttachmentWarning
      ) {
        this.setState({ showAttachmentWarning: true });
        return Promise.reject(new SubmissionError({}));
      } else if (
        this.props.newsMessageAction !== "edit" &&
        this.props.superEditPermission === false &&
        this.props.obdnConfig.scenario === "obdnConsent" &&
        this.state.costAccepted === false
      ) {
        this.setState({showCostWarning: true});
        return Promise.reject(new SubmissionError({}));
      } else if (this.state.test === true && !this.state.disregardTestWarning) {
        this.setState({showTestWarning: true});
        return Promise.reject(new SubmissionError({}));
      } else {
        return Promise.resolve(this.props.onSubmit());
      }
    };

    const showPublicationService = () => {
      return !(
        this.props.newsMessageAction === "edit" &&
        this.props.currentMessageStatus.toLowerCase() === "published" &&
        this.props.globalPermissionList.some((p) => p === "news.editPublished")
      );
    };

    const showSetAsTestMessage = () => {
      return (
          isSuperUser(this.props.userLoggedIn) &&
          this.props.newsMessageAction !== "published" &&
          this.props.currentMessageStatus.toLowerCase() !== "published"
      );
    };

    return (
      <form autoComplete="off">
        <FlexContainer column>
          <PageHeading margin={[0, 0, 16, 0]}>
            <FlexContainer row>
              {this.props.newsMessageAction === "edit"
                  ? t("edit_message")
                  : t("new_message_header_form_one")}
              {showHelp ? (
                  <HelpIcon
                      language={i18n.language}
                      fileName={"newsmessage"}
                      margin={[11, 0, 6, 5]}
                  />
              ) : undefined}
            </FlexContainer>
          </PageHeading>
          <FlexContainer spaceBetween row margin={[0, 0, 24, 0]}>
            <Field
                component={SelectDropDown}
                t={this.props.t}
                placeholder={t("select")}
                options={this.props.companyOptionList}
                name="issuer"
                label={t("issuer")}
                onSelect={this.props.onSelectIssuer}
                hiddenToolTip={true}
            />
            <Field
                component={SelectDropDown}
                t={this.props.t}
                placeholder={t("select")}
                options={this.props.securitiesOptionList}
                name="instrument"
                label={t("Instrument")}
                forceUpdate
            />

            {this.props.templateList != null &&
            this.props.templateList.length === 0 ? (
                <Field
                    component={SelectDropDown}
                    t={this.props.t}
                    hiddenToolTip={false}
                    placeholder={t("select")}
                    options={this.props.categoriesOptionList}
                    name="category"
                    label={t("category")}
                    onSelect={this.props.onSelectCategory}
                    forceUpdate
                />
            ) : (
                <Field
                    component={SelectDropDown}
                    t={this.props.t}
                    placeholder={t("select")}
                    options={this.props.templateList || []}
                    name="template"
                    label={t("category")}
                    onSelect={this.props.onSelectTemplate}
                />
            )}
          </FlexContainer>

          {this.props.messageParams.validLanguages && this.props.messageParams.validLanguages.map((lang) => (
              <FlexContainer margin={[0, 0, 24, 0]} key={lang}>
                <Fields
                    names={["title_" + lang, "message_" + lang, "attachments_" + lang]}
                    getAttachment={this.props.getAttachment}
                    component={MessageBox}
                    header={t("new_message_" + lang)}
                    titlePlaceholder={t("title")}
                    messagePlaceholder={t("new_message_placeholder")}
                    info={t("no_attachments")}
                    t={this.props.t}
                    language={lang}
                    maxFileSize={this.props.maxFileSize}
                    maxNumFiles={this.props.maxNumFiles}
                    fileType={this.props.fileType}
                    showHelp={this.props.showHelp}
                    validExtensions={this.props.messageParams.validExtensions}
                />
              </FlexContainer>
          ))}

          {this.props.messageParams.validLanguages && this.props.messageParams.validLanguages.length > 1 && (
              <DistrubutionInfo>{t("distrubutionInfo")}</DistrubutionInfo>
          )}

          {showPublicationService() ? (
              <FlexContainer
                  hidden={
                    !(
                        this.props.obdnConfig != null &&
                        this.props.obdnConfig.scenario.match(
                            /obdnNoConsent|obdnConsent/g
                        ) == null
                    )
                  }
                  greyBackground
                  padding={[0, 16, 16, 16]}
                  margin={[24, 0, 0, 0]}
              >
                <PageHeading
                    hidden={
                        this.props.obdnConfig != null &&
                        this.props.obdnConfig.obdnLocked === true
                    }
                >
                  {t("obdistubution")}
                  {showHelp ? (
                      <HelpIcon
                          language={i18n.language}
                          fileName={"newsobdn"}
                          margin={[11, 0, 6, 5]}
                      />
                  ) : undefined}
                </PageHeading>

                <FlexContainer>
                  <FlexContainer width={"auto"}>
                    <Label margin={[0, 0, 8, 0]}>
                      {t(
                          this.props.obdnConfig != null
                              ? this.props.obdnConfig.scenario + "_header"
                              : ""
                      )}{" "}
                    </Label>
                    <Field
                        name="distributionRequest"
                        component={RadioButtonGroup}
                        isObdnActive={this.state.isObdnActive}
                        setObdnOn={setObdnOn}
                        setObdnOff={setObdnOff}
                        obdnConfig={this.props.obdnConfig}
                        showScenarioModal={() =>
                            this.setState({showScenarioModal: true})
                        }
                        t={t}
                    />
                  </FlexContainer>

                  <DistrubutionBody>
                    {t(
                        this.props.obdnConfig != null
                            ? this.props.obdnConfig.scenario + "_description"
                            : ""
                    )}
                  </DistrubutionBody>
                </FlexContainer>
              </FlexContainer>
          ) : undefined}

          {showSetAsTestMessage() &&
              <FlexContainer
                  greyBackground
                  padding={[0, 16, 16, 16]}
                  margin={[24, 0, 0, 0]}
              >
                <PageHeading>{t("test_message")}</PageHeading>
                  <FlexContainer width={"auto"}>
                      <Field
                          component={CheckBox}
                          margin={[0, 0, 8, 0]}
                          label={t("test_message_mark")}
                          name={"test"}
                          onChange={() =>
                              this.setState({test: !this.state.test})
                          }
                      />
                    {this.state.test &&
                        <Label margin={[0, 0, 0, 0]} color={"red"}>
                          {t("test_message_not_sent_to_costumers")}
                        </Label>
                    }

                  </FlexContainer>
              </FlexContainer>
          }


          {this.props.company &&
          this.props.publicProps &&
          this.props.publicProps["global.module.emaildist"] &&
          this.props.publicProps["global.module.emaildist"] === "true" &&
          ((this.props.company.newsAgreement !== undefined &&
                  this.props.company.newsAgreement !== null &&
                  this.props.company.newsAgreement >= 0) ||
              (this.props.userOrganisation &&
                  this.props.userOrganisation.internal &&
                  this.props.userOrganisation.internal === true)) ? (
              <FlexContainer width={"50%"}>
                <PageHeading>{t("email_distribution")}</PageHeading>
                {(this.props.distributionLists &&
                    this.props.distributionLists.length > 0) ||
                (this.props.selectedDistributionLists &&
                    this.props.selectedDistributionLists.length > 0) ? (
                    <FlexContainer>
                      <Label>{t("email_distribution_to_these_lists")}</Label>
                      <SearchableSelectNew
                          options={this.props.distributionLists}
                          t={t}
                          onSelect={this.props.selectList}
                          margin={[5, 0, 0, 0]}
                      />
                      {this.props.selectedDistributionLists.map((list, index) => {
                        return (
                            <FlexContainer
                                row
                                key={"container_" + list.value}
                                margin={[5, 0, 0, 0]}
                            >
                              <Icon
                                  onClick={() => this.props.deselectList(index)}
                                  src="/icons/red-cross-24.svg"
                                  width="13px"
                                  height="13px"
                                  alt={t("delete_attachment")}
                                  title={t("delete_attachment")}
                                  margin={[4, 6, 0, 0]}
                                  key={"icon_" + list.value}
                              />
                              <Label key={"label_" + list.value}>{list.label}</Label>
                            </FlexContainer>
                        );
                      })}
                    </FlexContainer>
                ) : (
                    <Label>{t("email_distribution_no_lists")}</Label>
                )}
              </FlexContainer>
          ) : undefined}
          <FlexContainer row vAlignCenter flexEnd margin={[40, 0, 24, 0]}>
            <Button.Standard
                margin={[0, 16, 0, 0]}
                onClick={this.props.handleSubmit(onSubmit)}
            >
              {t("next_step")}
            </Button.Standard>
            <Button.Text onClick={this.props.onCancel}>
              {" "}
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        </FlexContainer>

        <ConfirmModal
          center
          header={t("cost_warning")}
          isOpen={this.state.showCostWarning}
          onClose={() => this.setState({ showCostWarning: false })}
          confirmText={t("continue")}
          cancelText={t("cancel")}
          onConfirm={() => {
            this.setState(
              {
                showCostWarning: false,
                costAccepted: true,
              },
              this.props.handleSubmit(onSubmit)
            );
          }}
        >
          <Text>
            {" "}
            {this.props.t("cost_warning_info1")}
            {getLanguage() === "No" ? (
              <StyledLink
                target="_blank"
                href={
                  this.props.config && this.props.config.announcementCostLinkNo
                    ? this.props.config.announcementCostLinkNo
                    : "https://www.euronext.com/en/corporate-services/oslo-bors-publication-service "
                }
              >
                {this.props.config && this.props.config.homepageName
                  ? this.props.config.homepageName
                  : "Oslo Børs"}
              </StyledLink>
            ) : (
              <StyledLink
                target="_blank"
                href={
                  this.props.config && this.props.config.announcementCostLinkEn
                    ? this.props.config.announcementCostLinkEn
                    : "https://www.euronext.com/en/corporate-services/oslo-bors-publication-service "
                }
              >
                {this.props.config && this.props.config.homepageName
                  ? this.props.config.homepageName
                  : "Oslo Børs"}
              </StyledLink>
            )}
            .
          </Text>

          <Text>{this.props.t("cost_warning_info2")} </Text>
          <Text>
            {this.props.t("cost_warning_info3")}
            <StyledLink href={"mailto:corporate.services@oslobors.no"}>
              corporate.services@oslobors.no
            </StyledLink>
            {this.props.t("cost_warning_info4")}
          </Text>
        </ConfirmModal>

        <ConfirmModal
          center
          header={t("attachment_warning")}
          isOpen={this.state.showAttachmentWarning}
          onClose={() => this.setState({ showAttachmentWarning: false })}
          confirmText={t("continue")}
          cancelText={t("cancel")}
          onConfirm={() => {
            this.setState(
              {
                showAttachmentWarning: false,
                disregardAttachmentWarning: true,
              },
              this.props.handleSubmit(onSubmit)
            );
          }}
        >
          <p>{t("attachment_warning_info")}</p>
        </ConfirmModal>

        <ConfirmModal
            center
            header={t("test_message")}
            isOpen={this.state.showTestWarning}
            onClose={() => this.setState({ showTestWarning: false })}
            confirmText={t("continue")}
            cancelText={t("cancel")}
            onConfirm={() => {
              this.setState(
                  {
                    showTestWarning: false,
                    disregardTestWarning: true
                  },
                  this.props.handleSubmit(onSubmit)
              );
            }}
        >
          <p>{t("test_warning_info")}</p>
        </ConfirmModal>

        <Modal
          center
          header={t("obdn_warning")}
          isOpen={this.state.showScenarioModal}
          onClose={() => this.setState({ showScenarioModal: false })}
        >
          <FlexContainer column>
            <p>
              {this.props.obdnConfig != null
                ? t(this.props.obdnConfig.scenario + "_description_modal")
                : ""}
            </p>
            <FlexContainer row flexEnd>
              <Button.Standard
                onClick={() => this.setState({ showScenarioModal: false })}
              >
                {t("ok")}
              </Button.Standard>
            </FlexContainer>
          </FlexContainer>
        </Modal>
      </form>
    );
  }
}

FirstForm = reduxForm({
  form: "newNewsMessage",
  destroyOnUnmount: false,
  //validate
})(FirstForm);

FirstForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    messageReducer,
    config,
    companyInformationReducer,
    loginUser,
    systemAdministratorReducer,
    form,
  } = state;

  return {
    currentMessageStatus: messageReducer.message.data
      ? messageReducer.message.data.messageStatus
      : "new",
    messagePermissionList: messageReducer.messagePermissionList.list,
    globalPermissionList: messageReducer.globalMessagePermissionList.list,
    messageParams: messageReducer.messageParams || {},
    config: config.config,
    company: companyInformationReducer.getCompany.isFetched
      ? companyInformationReducer.getCompany.list
      : undefined,
    userOrganisation: loginUser.userOrganisation,
    publicProps: systemAdministratorReducer.publicProps.object || {},
    newsMessageForm:
      form.newNewsMessage && form.newNewsMessage.values
        ? form.newNewsMessage.values
        : {},
  };
}

export default connect(mapStateToProps)(FirstForm);
